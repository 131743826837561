<template>
  <el-table
    :data="data"
    :row-style="{ backgroundColor: '#ebeef5' }"
    :header-row-style="{ backgroundColor: '#ebeef5' }"
    :header-cell-style="{ backgroundColor: '#ebeef5' }"
    :row-class-name="getRowClasses"
    :row-key="row_key"
    :expand-row-keys="expandRowKeys"
    @expand-change="handleExpandChange"
  >
    <el-table-column type="expand">
      <template slot-scope="scope">
        <massive-reserve-from-one-server-list
          v-if="scope.row.transfers.length > 1"
          :data="scope.row.transfers"
          :go-to-lk-operator="goToLkOperator"
          :is-return-button-loading="isReturnButtonLoading"
          :handle-return-cameras-button="handleReturnCamerasButton"
        />
      </template>
    </el-table-column>
    <el-table-column label="Сервер" prop="server_name_from">
      <template slot-scope="scope">
        <el-tooltip
          content="Перейти в ЛК Оператора"
          :open-delay="1000"
        >
          <el-link type="primary" @click="goToLkOperator(scope.row.server_from, token)" class="link">
            {{ scope.row.server_from.name }}
          </el-link>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column label="Резервный сервер" prop="server_name_to">
      <template slot-scope="scope">
        <el-tooltip content="Перейти в ЛК Оператора" :open-delay="1000">
          <el-link type="primary" @click="goToLkOperator(scope.row.transfers[0].server_to, token)" class="link">
            {{ scope.row.transfers[0].server_to.name }}
          </el-link>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column label="Статус" prop="status_description">
      <template slot-scope="scope">
        <router-link v-if="scope.row.transfers[0].is_report_accessible === 1" class="link" :to="{ name: 'reserve-transfers-report:report', params: { id: scope.row.transfers[0].id_reserve_transfer } }">
          {{ scope.row.transfers[0].status_description }}
        </router-link>
        <template v-else>
          {{ scope.row.transfers[0].status_description }}
        </template>
      </template>
    </el-table-column>
    <el-table-column label="Дата/время" prop="start_dt">
      <template slot-scope="scope">
        <span>{{ scope.row.transfers[0].start_dt }}</span>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.status !== 11"
          class="return_button"
          size="small"
          type="primary"
          :loading="isReturnButtonLoading"
          @click="() => handleReturnCamerasButton(scope.row.transfers[0])"
        >
          Вернуть камеры на свой сервер
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState } from "vuex";
import MassiveReserveFromOneServerList from "./MassiveReserveFromOneServerList";

export default {
  name: "MassiveReserveTransferList",
  components: { MassiveReserveFromOneServerList },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    goToLkOperator: {
      type: Function,
      default: () => {},
    },
    isReturnButtonLoading: {
      type: Boolean,
      default: false,
    },
    handleReturnCamerasButton: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      expandRowKeys: [],
    };
  },
  computed: {
    ...mapState("auth", ["token"]),
  },
  methods: {
    getRowClasses({ row }) {
      return row.transfers.length === 1 ? "no-expand" : null;
    },
    row_key(row) {
      return row.id;
    },
    handleExpandChange(row) {
      const id = row.id;
      const lastId = this.expandRowKeys[0];
      // disable multiple row expanded
      this.expandRowKeys = id === lastId ? [] : [id];
    },
  },
};
</script>

<style>
</style>
