<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-row justify="space-between" :gutter="20">
          <el-col :span="24">
            <h2 class="page__title">
              Редактирование сервера кластера массового резервирования
            </h2>
          </el-col>
        </el-row>
        <el-form ref="form" :rules="rules" :model="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="server_domain" label="Имя">
                <el-input v-model="form.server_domain" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Статус сервера" prop="id_server_mode">
                <select-servers-mode v-model="form.id_server_mode" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Максимальное количество камер" prop="max_cameras_count">
                <el-input-number size="small" :min="0" v-model="form.max_cameras_count" :max="5000" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Размер хранилища [ТБ]" prop="archive_size">
                <el-input-number id="archive_size" size="small" v-model="form.archive_size" :min="0" :max="5000" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Чистить архивы" prop="clean_archive">
                <el-switch v-model="form.clean_archive" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="Версия Nimble">
                <el-input size="small" readonly :value="form.nimble_version" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Идентификатор" prop="server_id">
                <el-input v-model="form.server_id" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Забронировано памяти">
                <el-input
                  v-if="form.archive_size_used !== null"
                  style="margin-top: 4px"
                  size="small"
                  readonly
                  :value="form.archive_size_used"
                >
                  <template slot="append"> ТБ </template>
                </el-input>
                <strong v-else>Нет данных</strong>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Камер на сервере">
                <el-input v-if="form.cameras_count !== null" size="small" readonly :value="form.cameras_count" />
                <strong v-else>Нет данных</strong>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Управление через AVC панель" prop="is_managed_by_avc_panel">
                <el-switch v-model="form.is_managed_by_avc_panel" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
            <el-col v-if="form.is_managed_by_avc_panel" :span="12">
              <el-form-item label="API-ключ AVC панели" prop="avc_panel_api_key">
                <el-input v-model="form.avc_panel_api_key" size="small" placeholder="api_key" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Адрес ЦОДа" prop="id_data_center">
                <select-data-center v-model="form.id_data_center" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div
        v-if="activeTransfers.length > 0"
      >
        <h3>Возврат камер на свои сервера</h3>
        <div class="buttons__wrapper">
          <el-button
            v-for="transfer in activeTransfers"
            :key="transfer.id_reserve_transfer"
            :loading="isReturnButtonLoading"
            class="page__active_button"
            type="primary"
            @click="() => handleReturnCamerasButton(transfer)"
          >
            Вернуть на {{ transfer.server_name }}
          </el-button>
        </div>
      </div>
    </main>
    <el-footer class="tab__footer page__active_bar">
      <el-button
        :loading="loading"
        class="page__active_button"
        type="success"
        icon="el-icon-check"
        @click="onSave"
      >
        Сохранить
      </el-button>
      <el-button
        :loading="loading"
        class="page__active_button"
        type="primary"
        @click="dialogDvr = true"
      >
        Перенести камеры
      </el-button>
    </el-footer>
    <dvr-server-massive-reserve-dialog
      :id-server="form.id"
      :visible="dialogDvr"
      :loading="camerasMoveLoading"
      confirm-button-text="Перенести"
      @close="dialogDvr = false"
      @save="onMoveCameras"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import isUndefined from "lodash/isUndefined";
import { length, required } from "@/core/helpers/validators";
import { ApiService } from "@/api";
import SelectServersMode from "../features/SelectServersMode";
import SelectDataCenter from "../features/SelectDataCenter";
import DvrServerMassiveReserveDialog from "../features/DvrServerMassiveReserveDialog";

export default {
  name: "ServerMassiveReserveEdit",
  components: {
    SelectDataCenter,
    SelectServersMode,
    DvrServerMassiveReserveDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      camerasMoveLoading: false,
      isReturnButtonLoading: false,
      dialogDvr: false,
      activeTransfers: [],
      form: {
        id: null,
        server_domain: "",
        id_server_mode: null,
        server_id: null,
        clean_archive: 1,
        archive_size: null,
        max_cameras_count: null,
        archive_size_used: null,
        cameras_count: null,
        is_managed_by_avc_panel: 1,
        avc_panel_api_key: "",
        id_data_center: null,
        nimble_version: null,
      },
      rules: {
        server_domain: [required(), length(0, 255)],
        id_server_mode: [required("number")],
        server_id: [required(), length(3, 100)],
        clean_archive: [required("number")],
        archive_size: [required("number")],
        max_cameras_count: [required("number")],
        is_managed_by_avc_panel: [required("number")],
      },
    };
  },
  computed: {
    ...mapState("dvrServersMassiveReserve", {
      server: (state) => state.server,
    }),
  },
  watch: {
    server(newServer) {
      const { active_transfers, ...server } = newServer;

      const availableFields = Object.keys(this.form);
      availableFields.forEach((key) => {
        this.form[key] = server[key];
      });

      this.activeTransfers = !isUndefined(active_transfers) ? active_transfers : [];
    },
  },
  created() {
    this.getServer(this.id)
      .then(() =>
        this.isSwitchBlocked = this.form.is_managed_by_avc_panel === 1
      );
  },
  methods: {
    ...mapActions("dvrServersMassiveReserve", {
      updateServer: "UPDATE_SERVER",
      getServer: "GET_DVR_SERVER_MASSIVE_RESERVE",
    }),
    ...mapActions("reserveTransfers", {
      returnCameras: "RETURN_CAMERAS",
    }),
    onMoveCameras(newServerId) {
      if (newServerId == this.id) {
        return this.$message.error("Новый сервер должен отличаться от текущего");
      }

      this.camerasMoveLoading = true;
      ApiService.dvrserversMassiveReserve
        .moveCameras(this.id, newServerId)
        .then(() => {
          this.$message.success("Камеры успешно перенесены");
        })
        .catch((e) => {
          this.$message.error(e.response.data.error || "Произошла ошибка при переносе камер");
        })
        .finally(() => {
          this.camerasMoveLoading = false;
        });
    },
    onSave() {
      let isValidate;
      this.$refs["form"].validate((status) => {
        isValidate = status;
      });

      if (!isValidate) return false;

      this.loading = true;
      this.updateServer({ id: this.id, newServer: this.form })
        .then(() => {
          this.$message({
            message: "Данные успешно обновлены",
            type: "success",
          });
        })
        .catch((e) => {
          this.$message({
            message: e.response.data.error || "Неизвестная ошибка",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleReturnCamerasButton(transfer) {
      this.$confirm(
        `Вернуть камеры на сервер ${transfer.server_name} ?`,
        "Возврат камер",
        {
        confirmButtonText: "Вернуть",
      })
        .then(() => {
          this.isReturnButtonLoading = true;

          this.returnCameras(transfer.id_reserve_transfer)
            .then(() => {
              this.$message({
                message: 'Камеры возвращены',
                type: 'success',
              });
              this.getServer(this.id);
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message: e.response.data.error || "Неизвестная ошибка",
              });
            });
        })
        .catch((e) => {
          if (e !== 'cancel') {
            this.$message({
              type: "error",
              message: e.response.data.error || "Неизвестная ошибка",
            });
          }
        })
        .finally(() => { this.isReturnButtonLoading = false });
    },
  },
};
</script>

<style>
.buttons__wrapper {
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-bottom: 10px;
}
</style>
