class DvrServerMassiveReserveService {
  constructor(http) {
    this.http = http;
  }

  getList(params = { page: 1, count: 10 }) {
    return this.http.get(`/admin/dvr-servers/massive-reserve`, { headers: params });
  }

  create(newServer) {
    return this.http.post(`/admin/dvr-servers/massive-reserve`, newServer);
  }

  getServer(idServer) {
    return this.http.get(`/admin/dvr-servers/massive-reserve/${idServer}`);
  }

  update(id, newServer) {
    return this.http.put(`/admin/dvr-servers/massive-reserve/${id}`, newServer);
  }

  moveCameras(id_server_from, id_server_to) {
    return this.http.put(`/admin/dvr-servers/massive-reserve/move-cameras`, {
      id_server_from,
      id_server_to,
    });
  }

  getRecommendPaginate(params = { id_server: "", page: 1, search: "", count: 50 }) {
    return this.http.get(`/admin/dvr-servers/massive-reserve/recommend/paginate`, { headers: params })
      .then((res) => res.data);
  }

  getRecommendReserve(id) {
    return this.http.get(`admin/dvr-servers/massive-reserve/recommend/${id}/reserve`)
      .then((res) => res.data);
  }
}

export default DvrServerMassiveReserveService;
