<template>
  <el-table ref="table" :data="customServersList">
    <el-table-column prop="server_cluster_name" label="Кластер" width="100" />
    <el-table-column label="Название сервера">
      <template slot-scope="scope">
        <el-tooltip content="Перейти в ЛК Оператора" :open-delay="1000">
          <el-link type="primary" @click="goToLKOperator(scope.row, token)" class="link">
            {{ scope.row.server_domain }}
          </el-link>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column prop="is_managed_by_avc_panel" label="Панель управления" align="center" width="120" >
      <template slot-scope="scope">
        <span>
          {{ scope.row.is_managed_by_avc_panel ? "AVC" : "WMS" }}
        </span>
      </template>
    </el-table-column>
    <el-table-column align="center" width="180" label="">
      <template slot="header">
        <span>
          Хранилище [ТБ]<br />
          (Занято/Общий)
        </span>
      </template>
      <template slot-scope="scope">
        <span :class="scope.row.classArchive">
          {{ scope.row.archive_size_used || 0 }} / {{ scope.row.archive_size || 0 }}
        </span>
      </template>
    </el-table-column>
    <el-table-column align="center" width="180" label="">
      <template slot="header">
        <span>
          Камеры <br />
          (Онлайн/На сервере/Всего)
        </span>
      </template>
      <template slot-scope="scope">
        <span :class="scope.row.classCameras">
          {{ scope.row.cameras_count_online || 0 }} / {{ scope.row.cameras_count || 0 }} /
          {{ scope.row.max_cameras_count || 0 }}
        </span>
      </template>
    </el-table-column>
    <el-table-column align="center" width="90" prop="server_mode_name" label="Режим" />
    <el-table-column align="center" width="90" prop="server_mode_name" label="Статус API">
      <template slot-scope="scope">
        <i v-if="scope.row.api_status === 0" class="el-icon-error color_alarm"></i>
        <i v-else class="el-icon-success color_good"></i>
      </template>
    </el-table-column>
    <el-table-column width="110" prop="nimble_version" label="Версия Nimble" />
  </el-table>
</template>

<script>
import { mapState } from "vuex";
import formatAppUrl from "@/core/helpers/formatAppUrl";
import Vue from "vue";
import { VK_OPERATOR_TOKEN_COOKIE_KEY } from "@/core/helpers/const";

const getClassName = (total, used) => {
  if (used / total > 1) {
    return "color_alarm";
  } else if (used / total > 0.8 && used / total <= 1) {
    return "color_warning";
  } else {
    return "color_good";
  }
};

export default {
  name: "DvrServers",
  data() {
    return {
      fetch: false,
      query: "",
    };
  },
  props: {
    servers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    customServersList() {
      return this.servers.map((server) => {
        const cServer = Object.assign({}, server);
        cServer.classArchive = getClassName(server.archive_size, server.archive_size_used);
        cServer.classCameras = getClassName(server.max_cameras_count, server.cameras_count);
        return cServer;
      });
    },
    ...mapState("auth", ["token"]),
  },
  methods: {
    goToLKOperator(item, token) {
      const url = `${formatAppUrl(process.env.VUE_APP_LK_OPERATOR_DOMAIN || item.operator_domain)}auth?operator=${item.id_operator}&redirectPath=servers/edit/${item.id}`;
      Vue.$cookies.set(VK_OPERATOR_TOKEN_COOKIE_KEY, token);
      window.open(url, '_blank');
    },
  },
};
</script>

<style>
[class^="color_"] {
  font-weight: bold;
}
.color_good {
  color: #4caf50;
}
.color_warning {
  color: #ff9800;
}
.color_alarm {
  color: #f44336;
}
</style>
