import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { getToken } from "@/components/Auth/store/helpers";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "DashBoard",
    component: () => import("@/components/Dashboard"),
    redirect: { name: "operators", params: { page: 1 } },
    children: [
      {
        path: "/operators/create",
        name: "operators:create",
        component: () => import("@/components/Operators/components/OperatorForm/OperatorCreate"),
      },
      {
        path: "/operators/:page",
        name: "operators",
        component: () => import("@/components/Operators/components/OperatorsList/OperatorsList"),
      },
      {
        path: "/operators/edit/:id",
        name: "operators:edit",
        props: true,
        component: () => import("@/components/Operators/components/OperatorForm/OperatorEdit"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/components/Profile"),
      },
      {
        path: "/users/:page",
        name: "users",
        component: () => import("@/components/Users"),
      },
      {
        path: "dvr-servers/:page",
        name: "dvr-servers",
        component: () => import("@/components/DvrServers/components/DvrServersList"),
      },
      {
        path: "dvr-servers-massive-reserve/create",
        name: "dvr-servers-massive-reserve:create",
        component: () => import("@/components/DvrServersMassiveReserve/forms/ServerCreate"),
      },
      {
        path: "dvr-servers-massive-reserve/:page",
        name: "dvr-servers-massive-reserve",
        component: () => import("@/components/DvrServersMassiveReserve/components/DvrServersMassiveReserveList"),
      },
      {
        path: "dvr-servers-massive-reserve/edit/:id",
        name: "dvr-servers-massive-reserve:edit",
        props: true,
        component: () => import("@/components/DvrServersMassiveReserve/forms/ServerEdit"),
      },
      {
        path: "servers/create",
        name: "servers:create",
        component: () => import("@/components/Servers/components/ServerCreate"),
      },
      {
        path: "/servers/:page",
        name: "servers",
        component: () => import("@/components/Servers/components/ServerList"),
      },
      {
        path: "servers/edit/:id",
        name: "servers:edit",
        props: true,
        component: () => import("@/components/Servers/components/ServerEdit"),
      },
      {
        path: "/m2m",
        name: "m2m",
        component: () => import("@/components/M2M/components/M2MList"),
      },
      {
        path: "models/create",
        name: "models:create",
        component: () => import("@/components/Models/components/FormCreate"),
      },
      {
        path: "models/:page",
        name: "models",
        component: () => import("@/components/Models/components/List"),
      },
      {
        path: "models/edit/:id",
        name: "models:edit",
        props: true,
        component: () => import("@/components/Models/components/FormEdit/Model"),
      },
      {
        path: "serial-numbers/create",
        name: "serial-numbers:create",
        component: () => import("@/components/SerialNumbers/components/FormCreate"),
      },
      {
        path: "reserve-transfers/:page",
        name: "reserve-transfers",
        component: () => import("@/components/ReserveTransfers/components/ReserveTransfersList"),
      },
      {
        path: "reserve-transfers-report/:id",
        name: "reserve-transfers-report:report",
        component: () => import("@/components/ReserveTransfers/components/ReserveTransferProblemsReport"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/Auth"),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some((r) => r.meta.public);
  const isLoggedIn = store.getters["auth/LOGGED_IN"];
  const profile = store.state.profile.profile;
  const onlyWhenLoggedOut = isPublic && to.matched.some((r) => r.meta.onlyWhenLoggedOut);
  const permission = to.meta.permission || "";
  const token = getToken();

  if (isPublic) {
    return isLoggedIn && onlyWhenLoggedOut ? next({ name: "DashBoard" }) : next();
  } else {
    if (!isLoggedIn || !token) {
      store.commit("auth/SET_TOKEN", null);
      return next({ name: "login", params: { redirect: to.path } });
    }

    // Для авторизованного
    if (!profile) {
      await store.dispatch("profile/GET_PROFILE");
    }

    // if (permission && !checkPermission(permission)) {
    if (permission) {
      return next({ name: "DashBoard" });
    }

    return next();
  }
});

export default router;
