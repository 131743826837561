<template>
  <el-table
    ref="table"
    class="list"
    :data="customServersList"
  >
    <el-table-column label="Название сервера">
      <template slot-scope="scope">
        <router-link
          class="link"
          :to="{ name: 'dvr-servers-massive-reserve:edit', params: { id: scope.row.id } }"
        >
          {{ scope.row.server_domain }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      width="90"
      prop="server_mode_name"
      label="Режим"
    />
    <el-table-column
      prop="is_managed_by_avc_panel"
      label="Панель управления"
      align="center"
      width="120"
    >
      <template slot-scope="scope">
        <span>
          {{ scope.row.is_managed_by_avc_panel ? "AVC" : "WMS" }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      width="180"
      label=""
    >
      <template slot="header">
        <span>
          Хранилище [ТБ]<br>
          (Занято/Общий)
        </span>
      </template>
      <template slot-scope="scope">
        <span :class="scope.row.classArchive">
          {{ scope.row.archive_size_used || 0 }} / {{ scope.row.archive_size || 0 }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      width="210"
      label=""
    >
      <template slot="header">
        <span>
          Камеры <br>
          (Онлайн/На сервере/Всего)
        </span>
      </template>
      <template slot-scope="scope">
        <span :class="scope.row.classCameras">
          {{ scope.row.cameras_count_online || 0 }} / {{ scope.row.cameras_count || 0 }} /
          {{ scope.row.max_cameras_count || 0 }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      width="90"
      prop="server_mode_name"
      label="Статус API"
    >
      <template slot-scope="scope">
        <i
          v-if="scope.row.api_status === 0"
          class="el-icon-error color_alarm"
        />
        <i
          v-else
          class="el-icon-success color_good"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState } from "vuex";

const getClassName = (total, used) => {
  if (used / total > 1) {
    return "color_alarm";
  } else if (used / total > 0.8 && used / total <= 1) {
    return "color_warning";
  } else {
    return "color_good";
  }
};

export default {
  name: "DvrServersMassiveReserveTable",
  computed: {
    ...mapState("dvrServersMassiveReserve", {
      items: (state) => state.dvrServersMassiveReserve.data,
    }),
    customServersList() {
      return this.items.map((server) => {
        const cServer = Object.assign({}, server);
        cServer.classArchive = getClassName(server.archive_size, server.archive_size_used);
        cServer.classCameras = getClassName(server.max_cameras_count, server.cameras_count);
        return cServer;
      });
    },
  },
};
</script>

<style>

</style>
