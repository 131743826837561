import { ApiService } from "@/api";

export default {
  GET_DVR_SERVERS({ commit }, payload) {
    return ApiService.dvrservers
      .getList({
        search: encodeURI(payload.query) || "",
        page: payload.page,
        count: payload.count,
      })
      .then((res) => {
        commit("SET_LIST_DVR_SERVERS", res.data);
        return res.data;
      });
  },
  GET_MODES() {
    return ApiService.dvrservers.getModes().then((modes) => {
      return modes;
    });
  },
  GET_DATA_CENTERS() {
    return ApiService.dvrservers.getDataCenters().then((modes) => {
      return modes;
    });
  },
}
