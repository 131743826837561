import actions from "./actions";
import mutations from "./mutations";
import { pagination } from "@/store/helpers";

export default {
  namespaced: true,
  state: {
    server: {},
    reserveDvrServers: [],
    dvrServersMassiveReserve: {
      ...pagination,
    },
  },
  actions,
  mutations,
};
