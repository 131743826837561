import axios from "axios";
import OperatorsService from "@/api/services/operators.service";
import UsersService from "@/api/services/users.service";
import DvrServerService from "@/api/services/dvrservers.service";
import DvrServerMassiveReserveService from "@/api/services/dvrserversMassiveReserve.service";
import ServerService from "@/api/services/servers.service";
import ModelsService from '@/api/services/models.service';
import LogsService from "@/api/services/logs.service";
import M2mService from "@/api/services/m2m.service";
import SerialNumbersService from "./services/serialnumbers.service";
import ReserveTransfersService from "@/api/services/reservetransfers.service";
import SideTaskTypesService from "@/api/services/sideTaskTypesService";

export const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

export const setToken = (token) => {
  http.defaults.headers.common["token"] = token;
};

export const ApiService = {
  operators: new OperatorsService(http),
  users: new UsersService(http),
  dvrservers: new DvrServerService(http),
  dvrserversMassiveReserve: new DvrServerMassiveReserveService(http),
  servers: new ServerService(http),
  m2m: new M2mService(http),
  models: new ModelsService(http),
  serialnumbers: new SerialNumbersService(http),
  logs: LogsService,
  sideTaskTypes: new SideTaskTypesService(http),
  reserveTransfersService: new ReserveTransfersService(http),
};
