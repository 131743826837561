import Vue from "vue";
import Vuex from "vuex";
import operators from "@/components/Operators/store";
import auth from "@/components/Auth/store";
import profile from "@/components/Profile/store";
import users from "@/components/Users/store";
import dvrServers from "@/components/DvrServers/store";
import dvrServersMassiveReserve from "@/components/DvrServersMassiveReserve/store";
import servers from "@/components/Servers/store";
import m2m from "@/components/M2M/store";
import models from "@/components/Models/store";
import reserveTransfers from "@/components/ReserveTransfers/store";
import sideTaskTypes from "@/core/components/SelectModelSidetask/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    operators,
    auth,
    profile,
    users,
    dvrServers,
    dvrServersMassiveReserve,
    servers,
    m2m,
    models,
    sideTaskTypes,
    reserveTransfers,
  },
});
