<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-row justify="space-between" :gutter="20">
          <el-col :span="24">
            <h2 class="page__title">
              Новый сервер кластера массового резервирования
            </h2>
          </el-col>
        </el-row>
        <el-form ref="form" :rules="rules" :model="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="server_domain" label="Имя">
                <el-input v-model="form.server_domain" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Статус сервера" prop="id_server_mode">
                <select-servers-mode v-model="form.id_server_mode" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Идентификатор" prop="server_id">
                <el-input v-model="form.server_id" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Чистить архивы" prop="clean_archive">
                <el-switch v-model="form.clean_archive" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="Размер хранилища [ТБ]" prop="archive_size">
                <el-input-number id="archive_size" size="small" v-model="form.archive_size" :min="0" :max="5000" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Максимальное количество камер" prop="max_cameras_count">
                <el-input-number size="small" :min="0" v-model="form.max_cameras_count" :max="5000" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Управление через AVC панель" prop="is_managed_by_avc_panel">
                <el-switch v-model="form.is_managed_by_avc_panel" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
            <el-col v-if="form.is_managed_by_avc_panel" :span="12">
              <el-form-item label="API-ключ AVC панели" prop="avc_panel_api_key">
                <el-input v-model="form.avc_panel_api_key" size="small" placeholder="api_key" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Адрес ЦОДа" prop="id_data_center">
                <select-data-center v-model="form.id_data_center" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button
        class="page__active_button"
        type="primary"
        icon="el-icon-check"
        :loading="isCreating"
        @click="onCreate"
      >
        Сохранить
      </el-button>
    </el-footer>
  </div>
</template>

<script>
import { length, required } from "@/core/helpers/validators";
import { ApiService } from "@/api";
import SelectServersMode from "../features/SelectServersMode";
import SelectDataCenter from "../features/SelectDataCenter";

export default {
  name: "ServerCreate",
  components: { SelectServersMode, SelectDataCenter },
  data() {
    return {
      isCreating: false,
      form: {
        server_domain: "",
        id_server_mode: null,
        server_id: "",
        clean_archive: 1,
        archive_size: null,
        max_cameras_count: null,
        is_managed_by_avc_panel: 1,
        avc_panel_api_key: null,
        id_data_center: null,
      },
      rules: {
        server_domain: [required(), length(0, 255)],
        id_server_mode: [required("number")],
        server_id: [required(), length(3, 100)],
        clean_archive: [required("number")],
        archive_size: [required("number")],
        max_cameras_count: [required("number")],
        is_managed_by_avc_panel: [required("number")],
        id_data_center: [required("number")],
      },
    };
  },
  methods: {
    onCreate() {
      this.$refs["form"].validate((status) => {
        return status ? this.createServer() : false;
      });
    },
    createServer() {
      this.isCreating = true;

      return ApiService.dvrserversMassiveReserve
        .create(this.form)
        .then((res) => {
          this.$message({
            type: "success",
            message: "Медиа-сервер успешно создан",
          });
          this.$router.push({ name: "dvr-servers-massive-reserve:edit", params: { id: res.data.id_server } });
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.response.data.error || "Неизвестная ошибка",
          });
        })
        .finally(() => {
          this.isCreating = false;
        });
    },
  },
};
</script>

<style></style>
