<template>
  <div class="massive-reserve-transfers-list-one-server--container">
    <el-table
      :data="transfers"
      :show-header="false"
    >
      <el-table-column prop="arrow">
        <div class="arrow-icon">
          <fa :icon="['fas', 'level-up-alt']" />
        </div>
      </el-table-column>
      <el-table-column
        prop="server_to-name"
      >
        <template slot-scope="scope">
          <el-tooltip
            content="Перейти в ЛК оператора"
            :open-delay="1000"
          >
            <el-link
              type="primary"
              @click="goToLkOperator(scope.row.server_to, token)"
              class="link"
            >
              {{ scope.row.server_to.name || 'Нет данных' }}
            </el-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="status_description">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.is_report_accessible === 1"
            class="link"
            :to="{ name: 'reserve-transfers-report:report', params: { id: scope.row.id_reserve_transfer } }"
          >
            {{ scope.row.status_description }}
          </router-link>
          <template v-else>
            {{ scope.row.status_description }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Дата/время" prop="start_dt" />
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status !== 11"
            class="return_button"
            size="small"
            type="primary"
            :loading="isReturnButtonLoading"
            @click="() => handleReturnCamerasButton(scope.row)"
          >
            Вернуть камеры на свой сервер
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faLevelUpAlt);

export default {
  name: "MassiveReserveFromOneServerList",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    serverId: {
      type: Number,
      default: null,
    },
    goToLkOperator: {
      type: Function,
      default: () => {},
    },
    isReturnButtonLoading: {
      type: Boolean,
      default: false,
    },
    handleReturnCamerasButton: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      transfers: [],
    };
  },
  computed: {
    ...mapState("auth", ["token"]),
  },
  created() {
    this.transfers = [...this.data];
    this.transfers.shift();
  },
};
</script>

<style>
.massive-reserve-transfers-list-one-server--container {
  margin-left: 48px;
}
.arrow-icon {
  width: 100%;
  color: #666666;
  transform: rotate(90deg);
  text-align: center;
  opacity: 0.4;
}
</style>
