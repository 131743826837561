import { ApiService } from "@/api";

export default {
  GET_DVR_SERVERS_MASSIVE_RESERVE_LIST({ commit }, payload) {
    return ApiService.dvrserversMassiveReserve
      .getList({
        page: payload.page,
        count: payload.count,
      })
      .then((res) => {
        commit("SET_LIST_DVR_SERVERS_MASSIVE_RESERVE", res.data);
        return res.data;
      });
  },
  GET_DVR_SERVER_MASSIVE_RESERVE({ commit }, id_server) {
    return ApiService.dvrserversMassiveReserve
      .getServer(id_server)
      .then((res) => {
        commit("SET_DVR_SERVER_MASSIVE_RESERVE", res.data);
        return res.data;
      });
  },
  GET_RECOMMENDED_RESERVE_SERVERS({ commit }, id) {
    return ApiService.dvrserversMassiveReserve.getRecommendReserve(id).then((servers) => {
      commit("SET_RECOMMEND_RESERVE_SERVERS", servers);
    });
  },
  UPDATE_SERVER(context, { id, newServer }) {
    return ApiService.dvrserversMassiveReserve.update(id, newServer);
  },
};
