export default {
  SET_LIST(state, payload) {
    state.reserveTransfers.data = payload.data;
    state.reserveTransfers.total = payload.total;
    state.reserveTransfers.count = payload.count;
  },
  SET_REPORT(state, payload) {
    state.report = payload;
  },
};
