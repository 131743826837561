<template>
  <el-dialog id="dialogServer" title="Медиа-сервер" :visible="visible" @close="onClose">
    <el-form id="formServer" label-position="right" label-width="150px">
      <el-form-item label="Медиа-сервер">
        <dvr-servers-massive-reserve-select v-model="newIdServer" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button id="closeServer" @click="onClose">Отменить</el-button>
      <el-button id="submitServer" :loading="loading" type="primary" @click="onSave">{{ confirmButtonText }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DvrServersMassiveReserveSelect from "./DvrServersMassiveReserveSelect";

export default {
  name: "DvrServerMassiveReserveDialog",
  components: { DvrServersMassiveReserveSelect },
  props: {
    idServer: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: "Сохранить",
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      newIdServer: null,
    };
  },
  watch: {
    idServer(idServer) {
      this.newIdServer = idServer;
    },
  },
  methods: {
    onSave() {
      this.$emit("save", this.newIdServer);
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
