<template>
  <el-select
    :value="modeId"
    remote
    :size="size"
    placeholder="Выберите режим"
    style="width: 100%"
    :loading="loading"
    v-on="$listeners"
  >
    <el-option
      v-for="mode in modes"
      :key="mode.id_server_mode"
      :value="mode.id_server_mode"
      :label="mode.name"
    />
  </el-select>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SelectServersMode",
  model: {
    prop: "mode-id",
    event: "change",
  },
  props: {
    modeId: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      loading: false,
      modes: [],
    };
  },
  created() {
    this.loading = true;
    this.pullModes()
      .then((res) => (this.modes = res))
      .finally(() => (this.loading = false));
  },
  methods: {
    ...mapActions("dvrServers", {
      pullModes: "GET_MODES",
    }),
  },
};
</script>
