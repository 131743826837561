<template>
  <el-select
    style="width: 100%"
    size="small"
    filterable
    remote
    :value="idDvrServer"
    :loading="loading && !recommendedServers.length"
    :remote-method="remoteMethod"
    v-on="$listeners"
  >
    <el-option
      v-for="server in recommendedServers"
      :key="server.id"
      :label="server.name"
      :value="server.id"
    >
      <span class="left_info">{{ server.name }}</span>
      <span class="right_info">
        <span :class="server.classStreams">
          <fa :icon="['fas', 'exchange-alt']" />
          {{ server.freely_streams }}
        </span>
        <span :class="server.classArchive">
          <fa :icon="['fas', 'database']" />
          {{ server.freely_archive }}
        </span>
      </span>
    </el-option>
    <el-option
      v-if="page*count < total"
      :value="0"
      disabled
      style="align-items: center; text-align: center"
    >
      <el-button
        :loading="loading && !queryLoading"
        type="text"
        style="width: 100%"
        size="mini"
        @click="nextPage"
      >
        {{ loading && !queryLoading ? "Загрузка" : "Показать ещe" }}
      </el-button>
    </el-option>
  </el-select>
</template>

<script>
import { FontAwesomeIcon as fa } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExchangeAlt, faDatabase } from "@fortawesome/free-solid-svg-icons";
import {ApiService} from "@/api";
library.add(faExchangeAlt, faDatabase);

export default {
  name: "DvrServersMassiveReserveSelect",
  components: {
    fa,
  },
  model: {
    prop: "idDvrServer",
    event: "change",
  },
  props: {
    idDvrServer: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
      count: 50,
      query: "",
      total: 0,
      queryLoading: false,
      loading: false,
      recommendedServers: []
    };
  },
  mounted() {
    this.pullRecommendedDvrServers(true);
  },
  methods: {
    clearData() {
      this.page = 1;
      this.total = 0;
    },
    remoteMethod(query) {
      this.queryLoading = true;
      this.query = encodeURI(query);
      this.clearData();
      this.pullRecommendedDvrServers().finally(() => {
        this.queryLoading = false;
      });
    },
    nextPage() {
      this.page = this.page + 1;
      this.pullRecommendedDvrServers(true);
    },
    pullRecommendedDvrServers(update = false) {
      let { page, query: search, count } = this;
      this.loading = true;
      let idDvrServer = this.idDvrServer ?? "";

      return ApiService.dvrserversMassiveReserve
        .getRecommendPaginate({ idDvrServer, page, search, count })
        .then(({ data: recommendedServers, total }) => {
          this.total = total;
          this.recommendedServers = (update ? [...this.recommendedServers, ...recommendedServers] : recommendedServers)
            .map((server) => {
              const customServer = Object.assign({}, server);
              customServer.classStreams = server.freely_streams <= 0 ? "color_alert" : "color_recommend";
              customServer.classArchive = server.freely_archive <= 0 ? "color_alert" : "color_recommend";

              return customServer;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>

<style>
.left_info {
  float: left;
  padding-right: 5px;
}
.right_info {
  float: right;
  text-align: left;
  min-width: 130px;
}
.color_recommend {
  color: #8bc34a;
}
.color_alert {
  color: #f44336;
}
</style>
