import { ApiService } from '@/api'

export default {
  GET_LIST({ commit }, payload) {
    return ApiService.reserveTransfersService
      .getList({
        page: payload.page,
        count: payload.count,
      })
      .then((res) => {
        commit("SET_LIST", res.data);
        return res.data;
      });
  },
  GET_REPORT({ commit }, id) {
    return ApiService.reserveTransfersService
      .getReport(id)
      .then((res) => {
        commit("SET_REPORT", res.data);
        return res.data;
      });
  },
  REBOOT_CAMERAS({ commit }, id) {
    return ApiService.reserveTransfersService
      .rebootCameras(id)
      .then((res) => {
        commit("SET_REPORT", res.data);
        return res.data;
      });
  },
  RETURN_CAMERAS(context, id) {
    return ApiService.reserveTransfersService
      .returnCameras(id)
      .then((res) => {
        return res.data;
      });
  },
};
