export default {
  SET_LIST_DVR_SERVERS_MASSIVE_RESERVE(state, payload) {
    state.dvrServersMassiveReserve.data = payload.data;
    state.dvrServersMassiveReserve.total = payload.total;
    state.dvrServersMassiveReserve.count = payload.count;
  },
  SET_DVR_SERVER_MASSIVE_RESERVE(state, payload) {
    state.server = payload;
  },
  SET_RECOMMEND_RESERVE_SERVERS(state, servers) {
    state.reserveDvrServers = servers;
  },
};
