<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2 class="page__title">Медиа-сервера кластера массового резервирования</h2>
        <dvr-servers-massive-reserve-table />
        <el-pagination
          hide-on-single-page
          class="list__pagination"
          layout="prev, pager, next"
          :page-size="count"
          :total="total"
          :current-page="page"
          @current-change="changePage"
        />
      </div>
    </main>
    <el-footer class="tab__footer page__active_bar">
      <el-button
        class="page__active_button"
        icon="el-icon-plus"
        type="primary"
        @click="$router.push({ name: 'dvr-servers-massive-reserve:create' })"
      >
        Добавить
      </el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import routeList from "@/core/mixins/routeList";
import DvrServersMassiveReserveTable from "./DvrServersMassiveReserveTable";

export default {
  name: "DvrServersMassiveReserveList",
  components: { DvrServersMassiveReserveTable },
  mixins: [routeList],
  computed: {
    ...mapState("dvrServersMassiveReserve", {
      total: (state) => state.dvrServersMassiveReserve.total,
      count: (state) => state.dvrServersMassiveReserve.count,
    }),
  },
  methods: {
    ...mapActions("dvrServersMassiveReserve", {
      getList: "GET_DVR_SERVERS_MASSIVE_RESERVE_LIST",
    }),
  },
}
</script>
