<template>
  <el-table :data="operators">
    <el-table-column prop="operator_name" label="Название">
      <template slot-scope="scope">
        <router-link class="link" :to="{ name: 'operators:edit', params: { id: scope.row.id_operator } }">
          {{ scope.row.operator_name }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column prop="short_name" label="Домен для приложений" />
    <el-table-column prop="login" label="Логин" />
    <el-table-column prop="status" label="Статус">
      <template slot-scope="scope">
        <el-tooltip
          effect="dark"
          placement="bottom-end"
          :content="statuses[scope.row.status] ? 'Оператор активен' : 'Оператор заблокирован'"
        >
          <el-switch
            v-model="statuses[scope.row.status]"
            :value="statuses[scope.row.status]"
            active-color="#13ce66"
            inactive-color="#ff4949"
            disabled
          />
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column prop="domain" label="Домен">
      <template slot-scope="scope">
        <a :href="scope.row.domain" target="_blank">
          {{ scope.row.domain }}
        </a>
      </template>
    </el-table-column>
    <el-table-column :width="120" align="center" prop="count_active_cameras" label="Активных камер">
      <template slot-scope="scope">
        <el-tooltip content="Перейти в Prometheus" :open-delay="1000">
          <a :href="scope.row.prometheus_link" class="link" target="_blank">
            {{ scope.row.count_active_cameras }}
          </a>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column :width="120" align="center" prop="id_operator" label="ID" />
    <el-table-column :width="80">
      <template slot-scope="scope">
        <el-tooltip content="Перейти в ЛК Оператора" :open-delay="1000">
          <el-button size="small" icon="el-icon-monitor" @click="goToLKOperator(scope.row, token)"/>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import formatAppUrl from "@/core/helpers/formatAppUrl";
import { VK_OPERATOR_TOKEN_COOKIE_KEY } from "@/core/helpers/const";

const statuses = {
  on: true,
  off: false,
};
export default {
  name: "OperatorsTable",
  props: {
    operators: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statuses,
    };
  },
  computed: {
    ...mapState("auth", ["token"]),
  },
  methods: {
    goToLKOperator(item, token) {
      const url = `${formatAppUrl(process.env.VUE_APP_LK_OPERATOR_DOMAIN || item.domain)}auth?operator=${item.id_operator}`;
      Vue.$cookies.set(VK_OPERATOR_TOKEN_COOKIE_KEY, token);
      window.open(url, '_blank');
    },
  },
};
</script>
