<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>Список процессов автоматического резервирования
          <i v-if="loading" class="el-icon-loading" style="margin-left: 10px;" />
        </h2>
        <el-table
          :data="items"
          :span-method="getSpanMethod"
          :row-class-name="getRowClasses"
          :row-key="row_key"
          :expand-row-keys="expandRowKeys"
          @expand-change="handleExpandChange"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <massive-reserve-transfer-list
                v-if="scope.row.type === 'massive'"
                :data="scope.row.groups"
                :go-to-lk-operator="goToLKOperator"
                :is-return-button-loading="isReturnButtonLoading"
                :handle-return-cameras-button="handleReturnCamerasButton"
              />
              <massive-reserve-from-one-server-list
                v-else
                :data="scope.row.groups[0].transfers"
                :go-to-lk-operator="goToLKOperator"
                :is-return-button-loading="isReturnButtonLoading"
                :handle-return-cameras-button="handleReturnCamerasButton"
              />
            </template>
          </el-table-column>
          <el-table-column label="Сервер" prop="server_name_from">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.type === 'single'"
                content="Перейти в ЛК Оператора"
                :open-delay="1000"
              >
                <el-link type="primary" @click="goToLKOperator(scope.row.groups[0].transfers[0].server_from, token)" class="link">
                  {{ scope.row.groups[0].transfers[0].server_from.name }}
                </el-link>
              </el-tooltip>
              <template v-else>
                <b>МАССОВОЕ РЕЗЕРВИРОВАНИЕ</b>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Резервный сервер" prop="server_name_to">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.type === 'single'"
                content="Перейти в ЛК Оператора"
                :open-delay="1000"
              >
                <el-link type="primary" @click="goToLKOperator(scope.row.groups[0].transfers[0].server_to, token)" class="link">
                  {{ scope.row.groups[0].transfers[0].server_to.name }}
                </el-link>
              </el-tooltip>
              <template v-else>
                {{ scope.row.groups[0].transfers[0].server_to.name }}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Статус" prop="status_description">
            <template slot-scope="scope">
              <template v-if="scope.row.type === 'massive'">
                {{ scope.row.status_description || 'Нет данных' }}
              </template>
              <template v-else>
                <router-link
                  v-if="scope.row.groups[0].transfers[0].is_report_accessible === 1"
                  class="link"
                  :to="{ name: 'reserve-transfers-report:report', params: { id: scope.row.groups[0].transfers[0].id_reserve_transfer } }"
                >
                  {{ scope.row.groups[0].transfers[0].status_description }}
                </router-link>
                <template v-else>
                  {{ scope.row.groups[0].transfers[0].status_description }}
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Дата/время" prop="start_date">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'single'">{{ scope.row.groups[0].transfers[0].start_dt }}</span>
              <template v-else>
                <span>{{ scope.row.start_date }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.type === 'single' && scope.row.status !== 11"
                class="return_button"
                size="small"
                type="primary"
                :loading="isReturnButtonLoading"
                @click="() => handleReturnCamerasButton(scope.row.groups[0].transfers[0])"
              >
                Вернуть камеры на свой сервер
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="list__pagination"
          layout="prev, pager, next"
          :page-size="count"
          :total="total"
          :current-page="page"
          @current-change="handleChangePage"
        />
      </div>
    </main>
  </el-container>
</template>

<script>
import routeList from "@/core/mixins/routeList";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import formatAppUrl from "@/core/helpers/formatAppUrl";
import { VK_OPERATOR_TOKEN_COOKIE_KEY } from "@/core/helpers/const";
import MassiveReserveTransferList from "./MassiveReserveTransferList";
import MassiveReserveFromOneServerList from "./MassiveReserveFromOneServerList";

export default {
  name: "ReserveTransfersList",
  components: { MassiveReserveTransferList, MassiveReserveFromOneServerList },
  mixins: [routeList],
  data() {
    return {
      loading: true,
      isReturnButtonLoading: false,
      expandRowKeys: [],
    };
  },
  computed: {
    ...mapState("reserveTransfers", {
      items: (state) => state.reserveTransfers.data,
      total: (state) => state.reserveTransfers.total,
      count: (state) => state.reserveTransfers.count,
    }),
    ...mapState("auth", ["token"]),
  },
  created() {
    this.loading = true;
    this.getList({
      page: this.page,
      count: this.count,
    }).then(() => (this.loading = false));
    this.intervalId = setInterval(() => {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
      this.getList({
        page: this.page,
        count: this.count,
      });
    }, 8000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions("reserveTransfers", {
      getList: "GET_LIST",
      returnCameras: "RETURN_CAMERAS",
    }),
    goToLKOperator(item, token) {
      const url = `${formatAppUrl(process.env.VUE_APP_LK_OPERATOR_DOMAIN || item.operator_domain)}auth?operator=${item.id_operator}&redirectPath=servers/edit/${item.id}`;
      Vue.$cookies.set(VK_OPERATOR_TOKEN_COOKIE_KEY, token);
      window.open(url, "_blank");
    },
    handleReturnCamerasButton(reserveTransfer) {
      this.$confirm(
        `Вернуть камеры с сервера ${reserveTransfer.server_to.name}
          на сервер ${reserveTransfer.server_from.name}`,
        "Возврат камер",
        {
          confirmButtonText: "Вернуть",
        }
      ).then(() => {
        this.isReturnButtonLoading = true;

        this.returnCameras(reserveTransfer.id_reserve_transfer)
          .then(() => {
            this.$message({
              message: "Камеры возвращены",
              type: "success",
            });
            this.getList({
              page: this.page,
              count: this.count,
            });
          })
          .catch((e) => {
            if (e !== "cancel") {
              this.$message({
                type: "error",
                message: e?.response?.data.error || "Неизвестная ошибка",
              });
            }
          })
          .finally(() => { this.isReturnButtonLoading = false });
      });
    },
    getSpanMethod({ row, columnIndex }) {
      if (row.type === "massive") {
        if (columnIndex === 1) {
          return [1, 2];
        } else if (columnIndex === 2) {
          return [0, 0];
        }
      }
    },
    getRowClasses({ row }) {
      return row.type === "single" && row.groups[0].transfers.length === 1 ? "no-expand" : null;
    },
    row_key(row) {
      return row.id_report;
    },
    handleExpandChange(row) {
      const id = row.id_report;
      const lastId = this.expandRowKeys[0];
      // disable multiple row expanded
      this.expandRowKeys = id === lastId ? [] : [id];
    },
    handleChangePage(page) {
      this.expandRowKeys = [];
      this.changePage(page);
    },
  },
};
</script>

<style>
.list,
.list__pagination {
  margin: 20px 0;
}
.list__pagination {
  text-align: center;
}
.no-expand .el-icon {
  display: none;
}
.no-expand .el-table__expand-icon {
  pointer-events: none;
}
.return_button {
  text-wrap: wrap;
  width: 144px;
}
</style>
